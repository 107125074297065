import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const FETCH_SEARCH_REDIRECTS = gql(`
  query SiteFixturesRedirects($handle: String) {
    siteFixtures(handle: $handle) {
      id
      __typename
      searchRedirects {
        id
        code
        redirects
      }
    }
  }
`)
