'use client'

import { usePathname, useSearchParams } from 'next/navigation'
import { useCallback, useRef, useState } from 'react'
import { createContainer } from 'unstated-next'

import { CartContainer } from '../../brunswick/containers/cart'
import { useGoogleTagManager } from '../../lib/hooks/useGoogleTagManager'

import { useLocalization } from './LocalizationProvider.client'

const useCartDialogImpl = () => {
  const { cart } = CartContainer.useContainer()
  const { region } = useLocalization()
  const { pushOpenMiniCart } = useGoogleTagManager()
  const [isOpen, setIsOpen] = useState(false)

  const pathname = usePathname()
  const searchParams = useSearchParams()
  const previousPathnameRef = useRef(pathname)
  const previousSearchParamsRef = useRef(searchParams)
  if (
    previousPathnameRef.current !== pathname ||
    previousSearchParamsRef.current !== searchParams
  ) {
    previousPathnameRef.current = pathname
    previousSearchParamsRef.current = searchParams
    setIsOpen(false)
  }

  const close = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const open = useCallback(() => {
    setIsOpen(true)
    pushOpenMiniCart(cart, region.currency)
  }, [cart, pushOpenMiniCart, region.currency])

  return {
    isOpen,
    close,
    open,
  }
}

const CartDialogContainer = createContainer(useCartDialogImpl)
CartDialogContainer.Provider.displayName = 'CartProviderImpl'

export const CartProvider = (props: { children?: React.ReactNode }) => {
  return (
    <CartContainer.Provider>
      <CartDialogContainer.Provider>{props.children}</CartDialogContainer.Provider>
    </CartContainer.Provider>
  )
}

export const useCartDialog = CartDialogContainer.useContainer
export const useCart = CartContainer.useContainer
