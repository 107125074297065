'use client'

import { useMemo } from 'react'
import { createContainer } from 'unstated-next'

import { ICheckoutClient } from '../../lib/cart-client/interfaces'
import { ShopifyStorefrontApiClient } from '../../lib/cart-client/ShopifyStorefrontApiClient'

import { useGraphqlClients } from './GraphqlClientsProvider.client'

function useCheckoutClientImpl(checkoutClient: ICheckoutClient | undefined) {
  return checkoutClient!
}

const CheckoutClientContainer = createContainer(useCheckoutClientImpl)
CheckoutClientContainer.Provider.displayName = 'CheckoutClientProviderImpl'

export const CheckoutClientProvider = ({ children }: { children?: React.ReactNode }) => {
  const clients = useGraphqlClients()
  const checkoutClient = useMemo(() => {
    return new ShopifyStorefrontApiClient({
      apolloClient: clients.shopifyClient,
    })
  }, [clients.shopifyClient])
  return (
    <CheckoutClientContainer.Provider initialState={checkoutClient}>
      {children}
    </CheckoutClientContainer.Provider>
  )
}

export const useCheckoutClient = CheckoutClientContainer.useContainer
