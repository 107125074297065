'use client'

import { LDClient, initialize } from 'launchdarkly-js-client-sdk'
import { useEffect, useMemo, useRef, useState } from 'react'
import { createContainer } from 'unstated-next'

import { gql } from '../../__generated__/graphql/catalog'
import {
  createLaunchDarklyClientConfig,
  createLaunchDarklyContext,
} from '../_config/Experimentation.config'

import { useAuthentication } from './AuthenticationProvider.client'
import { useNextQuery } from './GraphqlClientsProvider.client'
import { useSession } from './SessionProvider.client'
import { useTrackFeatureFlagDecision } from './TrackingProvider.client'

const createClient = ({
  context,
  initialState,
}: {
  context: ReturnType<typeof createLaunchDarklyContext>
  initialState: Record<PropertyKey, unknown> | undefined
}) => {
  if (typeof window === 'undefined') return undefined
  if (!process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_API_KEY) return undefined

  const config = createLaunchDarklyClientConfig({ bootstrap: initialState })
  const client = initialize(process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_API_KEY, context, config)

  return client
}

const useClientImpl = (initialState: Record<PropertyKey, unknown> | undefined) => {
  const { account } = useAuthentication()
  const { attributes } = useSession()

  const { data: portalData } = useNextQuery(
    gql(`
      query GetPortalInfoForLaunchDarklyContext {
        my {
          id
          portal {
            id
            name
          }
        }
      }
    `),
    {
      client: 'auth-supported',
      skip: !account,
    }
  )

  const context = useMemo(() => {
    return createLaunchDarklyContext({
      account,
      sessionAttributes: attributes,
      portal: portalData?.my.portal
        ? {
            id: portalData.my.portal.id,
            name: portalData.my.portal.name,
          }
        : undefined,
    })
  }, [account, attributes, portalData?.my.portal])
  const lastContextUsedRef = useRef(context)

  const clientRef = useRef<LDClient | undefined>(undefined)

  if (!clientRef.current) {
    clientRef.current = createClient({
      context,
      initialState,
    })
    lastContextUsedRef.current = context
  }

  if (clientRef.current && context !== lastContextUsedRef.current) {
    clientRef.current.identify(context)
    lastContextUsedRef.current = context
  }

  const [isClientReady, setIsClientReady] = useState(() => {
    const startedWithAClient = !!clientRef.current
    const startedWithFlagDecisions = !!initialState
    return startedWithAClient && startedWithFlagDecisions
  })

  const internalsRef = useRef<{
    disabledFeatures: Record<string, true>
    synchronousFlagsDisabled: boolean
  }>({
    disabledFeatures: {},
    synchronousFlagsDisabled: !isClientReady,
  })

  useEffect(() => {
    if (isClientReady) return

    clientRef.current
      ?.waitUntilReady()
      .then(() => {
        // If our client had to be started up, because no initialState was provided (we are in pages dir), we need to toggle on that we can now process flags.
        internalsRef.current.synchronousFlagsDisabled = false
        setIsClientReady(true)
      })
      .catch(() => {})
  }, [isClientReady])

  return {
    client: isClientReady ? clientRef.current : undefined,
    initialFlags: initialState,
    _internals: internalsRef.current,
  }
}

const ExperimentationContainer = createContainer(useClientImpl)
ExperimentationContainer.Provider.displayName = 'ExperimentationProviderImpl'
export const ExperimentationProvider = ExperimentationContainer.Provider

export function useFeatureFlag(options: {
  key: string
  defaultVariant: string
  ifAccessedPriorToDecisionInitialization: 'lock-pageview-to-defaultVariant'
  skip?: boolean
}): string

export function useFeatureFlag(options: {
  key: string
  defaultVariant: string
  ifAccessedPriorToDecisionInitialization: 'return-null-while-pending'
  skip?: boolean
}): null | string

export function useFeatureFlag({
  key,
  defaultVariant,
  ifAccessedPriorToDecisionInitialization,
  skip,
}: {
  key: string
  defaultVariant: string
  ifAccessedPriorToDecisionInitialization:
    | 'return-null-while-pending'
    | 'lock-pageview-to-defaultVariant'
  skip?: boolean
}) {
  const { client, initialFlags, _internals } = ExperimentationContainer.useContainer()
  const { trackFeatureFlagDecision } = useTrackFeatureFlagDecision()

  if (skip === true) {
    return defaultVariant
  }

  if (
    _internals.synchronousFlagsDisabled &&
    ifAccessedPriorToDecisionInitialization === 'lock-pageview-to-defaultVariant'
  ) {
    _internals.disabledFeatures[key] = true
  }

  if (_internals.disabledFeatures[key]) {
    return defaultVariant
  }

  if (!client && !initialFlags) {
    if (ifAccessedPriorToDecisionInitialization === 'lock-pageview-to-defaultVariant') {
      return defaultVariant
    } else {
      return null
    }
  }

  const decision = client
    ? `${client.variation(key, defaultVariant)}`
    : initialFlags?.[key]
    ? `${initialFlags[key]}`
    : defaultVariant

  trackFeatureFlagDecision({
    key,
    decision,
  })

  return decision
}
