import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const FETCH_BULK_COLLECTION = gql(`
  query FetchBulkCollectionData($handle: String!) {
    collection: optimizedCollection(handle: $handle, colorScope: ALL) {
      id
      items(first: 100, grouping: PRODUCT_GROUP) {
        nodes {
          id
          handle
          color
          colorInfo {
            availability
            name
            rawName
          }
          fit
          waitlistable
          shopifyId
          finalSale
          images(first: 1) {
            nodes {
              source
            }
          }
          priceRange
          rawFit
          productGroup {
            id
            briefProductDescription
            colorGroups {
              ...ColorGroup
            }
            defaultVariant {
              priceDetails {
                discountPrice {
                  amount
                }
                price {
                  amount
                }
              }
            }
            rawCategory
            category
            description
            fits
            genderCategory
            handle
            productsForAvailability: products {
              availableSizes
              colorInfo {
                rawName
              }
              rawFit
            }
            rawFits
            sizes
            style
            sizeChart: sizeChartCG {
              ...SizeChartFragment
            }
          }
          variants {
            color
            fit
            priceExplicitFmt
            discountPriceExplicitFmt
            priceDetails {
              discountPrice {
                amount
                currency
              }
              price {
                amount
                currency
              }
            }
            rawColor
            shopifyId
            size
            sku
            soldOut
          }
        }
      }
    }
    collectionView(handle: $handle) {
      title
      sections {
        __typename
        ... on CollectionViewHeroSection {
          id
          title
          subtitle
          imagePlacement
          imageDesktop
          imageMobile
          name
        }
        ... on CollectionGridSection {
          visualFiltersSectionV2 {
            tiles {
              id
              ctaText
              filter
              value
            }
          }
        }
      }
    }
  }
`)
