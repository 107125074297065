import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const PRODUCT_PAGE_QUERY = gql(`
  query ProductPageQuery(
    $handle: String!
    $collectionHandle: String
    $collectionScope: ProductGroupColorSelector
    $portalCollectionHandle: String
    $withPortalColors: Boolean!
  ) {
    productGroup(
      handle: $handle
      collectionHandle: $collectionHandle
    ) {
      id
      category
      genderCategory
      portalColorGroups: colorGroups(collectionHandle: $portalCollectionHandle, onlyScoped: true) @include(if: $withPortalColors) {
        ...ColorGroup
      }
      colorGroups(
        selector: $collectionScope
      ) {
        ...ColorGroup
      }
      description
      embroiderable
      embroideryImages {
        color
        embroideryOffsets
        fit
        index
        source
        kind
        type
      }
      iconBadge
      iconBadgePlacement
      priceRange {
        min
        max
      }
      productsForAvailability: products {
        ...ProductAvailabilityFragment
      }
      productGroupView(handle: $handle) {
        sections {
          __typename
          ...PageBannerSectionFragment
          ...PageHeroSectionFragment
          ...PageTileSectionFragment
        }
      }
      handle
      rtfFeatures
      fitDescription
      title
      sizes
      sizeChart: sizeChartCG {
        ...SizeChartFragment
      }
      style
      fits
      rawFits
    }
  }
`)
